import React from "react";
import PageTemplate from "./base";
import imgHero from "../../assets/image/industries/logistics.jpeg";

const Page = () => {
  return (
    <>
      <PageTemplate
        seoTitle="Free Scheduling Software for Manufacturing & Logistics | Camelo"
        metaDescription="Fast multi-location scheduling for manufacturing & logistics. Camelo allows you to schedule work, discuss with your staff across geographic boundaries."
        header="Fast multi-location scheduling for manufacturing & logistics."
        subHeader="Camelo is a better way to schedule, connect, and manage your team."
        imgUrl={imgHero}
      />
    </>
  );
};

export default Page;
